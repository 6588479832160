
export default {
  name: "SectionLayoutWrapper",
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: '',
    },
  },
};
