
export default {
  name: "SwiperCarrousel",
  props: {
    slides: Array,
    fixedHeight: {
      type: Boolean,
      default: false,
      required: false
    },
    isBasicGallery: {
      type: Boolean,
      default: false,
      required: false
    },
    isHeroGallery: {
      type: Boolean,
      default: false,
      required: false
    },
    autoplay: {
      type: Boolean,
      default: true,
      required: false
    },
    autoplayInterval: {
      type: Number,
      default: 4500,
      required: false
    },
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 4,
        slidesPerGroup: 1,
        // slidesOffsetBefore: 16,
        // slidesOffsetAfter: 14,
        autoplay: this.autoplay ? {delay: this.autoplayInterval} : false,
        loop: false,
        loopFillGroupWithBlank: false,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // renderBullet: function (index, className) {
          //   return '<span class="' + className + '">' + (index + 1) + "</span>";
          // },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // Responsive breakpoints
        // breakpoints: {
        //   // when window width is >= 320px
        //   320: {
        //     spaceBetween: 10,
        //     slidesPerView: 1.15,
        //     draggable: false,
        //   },
        //   // when window width is >= 768px
        //   768: {
        //     spaceBetween: 20,
        //     slidesPerView: 'auto',
        //     slidesOffsetBefore: 40,
        //     slidesOffsetAfter: 20,
        //     // slidesPerGroup: 1,
        //   },
        //   1220: {
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     slidesOffsetBefore: 40,
        //     slidesOffsetAfter: 40,
        //   },
        //   1280: {
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     slidesOffsetBefore: 40,
        //     slidesOffsetAfter: 20,
        //   },
        //   1500: {
        //     slidesPerView: 'auto',
        //     spaceBetween: 20,
        //     slidesOffsetBefore: 40,
        //     slidesOffsetAfter: 40,
        //   }
        // }
      }
    };
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
  },
  watch: {
    loadingAnimationFinished(newValue) {
      if(newValue) {
       this.setAutoplay()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeSwiper();
      this.setAutoplay();
    });
  },
  methods: {
    initializeSwiper() {
      const modules = this.isBasicGallery ?
        [this.$swiperModules.Pagination, this.$swiperModules.Autoplay, this.$swiperModules.Navigation] :
        [this.$swiperModules.Pagination, this.$swiperModules.Autoplay]
      this.swiper = new this.$swiper(this.$refs.swiperElement, {
        loop: true,
        modules: modules,
        ...this.swiperOptions
      });
      this.swiper.autoplay.stop();
    },
    setAutoplay() {
      if (this.autoplay) {
        this.swiper.autoplay.start();
        this.swiper.params.autoplay.delay = this.autoplayInterval;
      }
    }
  }
};
