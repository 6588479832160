
import imgPathsMixin from "@/mixins/imageMeta";

export default {
  name: "SingleColumnContent",
  props: {
    data: Object,
  },
  mixins: [imgPathsMixin],
  data() {
    return {
      windowWidth: 0,
      splitClient: null,
      splitActive: false,
    };
  },
  computed: {
    isMobile() {
      return this.$device.isMobile || this.windowWidth < 768;
    },
    imageAttributes() {
      return this.getAttribute(this.data?.image?.data, 'attributes');
    },
    imageMobileAttributes() {
      return this.getAttribute(this.data?.imageMobile?.data, 'attributes');
    },
  },
  mounted() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);

    if (this.isMobile) {
      this.initAnimationSequence();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;

      if (!this.isMobile) {
        if (this.splitActive) {
          this.splitActive = false
          this.splitClient.revert(this.$refs['split'])

          if (this.$ScrollTrigger) {
            this.$ScrollTrigger.getAll().forEach(st => st.kill());
          }
        }
      } else {
        if (!this.splitActive) {
          this.initAnimationSequence();
        }
      }
    },
    initAnimationSequence() {
      this.splitClient = this.$splitType.create(this.$refs['split'], {
        types: "words, chars"
      });
      let chars = this.splitClient.chars;
      // const content = this.$refs['content'];
      this.splitActive = true

      this.$gsap.from(chars, {
        duration: 0.8,
        opacity: .3,
        ease: "none",
        stagger: 0.2,
        scrollTrigger: {
          trigger: this.$refs['split'],
          start: "top 75%",
          end: "60% 75%",
          // pin: content,
          // markers: true,
          scroller: this.isMobile ? null : "[data-scroll-container]",
          scrub: true
        }
      });
    }
  }
}
