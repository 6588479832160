
export default {
  name: "CTAButton",
  props: {
    showMobileGalleryBtn: {
      type: Boolean,
      default: false,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
  }
}
