
import imgPathsMixin from '@/mixins/imageMeta';

export default {
  name: "SwiperCarrouselSlide",
  props: {
    slide: {
      type: Object,
      default: {},
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    isHeroGallery: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  mixins: [imgPathsMixin],
  computed: {
    imageAttributes() {
      return this.getAttribute(this.slide.image.data, 'attributes');
    },
  },
}
