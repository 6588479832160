
export default {
  name: "ResponsivePicture",
  props: {
    isImgFullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    imgID: {
      type: String,
      required: false,
      default: ''
    },
    imgClass: {
      type: String,
      required: false,
      default: ''
    },
    baseHeightOverride: {
      type: Boolean,
      required: false,
      default: false
    },
    alt: {
      type: String,
      default: "Image"
    },
    caption: {
      type: String,
      required: false,
    },
    smallURL: {
      type: String,
      required: true,
    },
    mediumURL: {
      type: String,
      required: false,
      default: ''
    },
    largeURL: {
      type: String,
      required: false,
      default: ''
    },
    xlargeURL: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    webpURL() {
      return this.toWebp(this.smallURL);
    }
  },
  methods: {
    getSizes() {
      if (this.isImgFullscreen) {
        return "(max-width: 320px) 250px," +
          "(max-width: 600px) 600px," +
          "(max-width: 1200px) 1000px," +
          "1920px";
      } else {
        return "(max-width: 640px) 250px," +
          "(max-width: 1240px) 600px," +
          "(max-width: 1920px) 1200px," +
          "1920px";
      }
    },
    getSrcSet(webp) {
      if (webp) {
        return this.toWebp(this.smallURL) + ' 250w, ' +
          (this.toWebp(this.mediumURL) || this.toWebp(this.smallURL)) + ' 600w, ' +
          (this.toWebp(this.largeURL) || this.toWebp(this.mediumURL) || this.toWebp(this.smallURL)) + ' 1200w, ' +
          (this.toWebp(this.xlargeURL) || this.toWebp(this.largeURL) || this.toWebp(this.mediumURL) || this.toWebp(this.smallURL)) + ' 1920w'
      } else {
        return this.smallURL + ' 250w, ' +
          (this.mediumURL || this.smallURL) + ' 600w, ' +
          (this.largeURL || this.mediumURL || this.smallURL) + ' 1200w, ' +
          (this.xlargeURL || this.largeURL || this.mediumURL || this.smallURL) + ' 1920w'
      }
    },
    toWebp(string) {
      return string.replace(/\.(jpg|png)/g, '.webp');
    }
  }
}
