
export default {
  name: "Hero",
  props: {
    data: Object,
  },
  data() {
    return {
      isGalleryVisible: true,
    };
  },
  mounted() {
    this.setFixedGalleryHeight();
    this.initHeroGalleryVisibility();
  },
  methods: {
    setFixedGalleryHeight() {
      const galleryElement = this.$refs['heroGallery'];

      if (galleryElement) {
        const computedHeight = galleryElement.clientHeight;
        galleryElement.style.height = `${computedHeight}px`;
      }
    },
    initHeroGalleryVisibility() {
      const galleryElement = this.$refs['heroGallery'];

      this.$ScrollTrigger.create({
        trigger: galleryElement,
        start: "bottom top",
        endTrigger: "html",
        end: "top top",
        scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
        onEnter: () => {
          this.isGalleryVisible = false
        },
        onLeaveBack: () => {
          this.isGalleryVisible = true
        },
        // markers: true,
      });
    },
  }
}
